import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  buttons: {
    fontWeight: `bold`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        一緒にプログラミングを始めてみませんか？
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        プログラミング学習で苦労していませんか？
        はむさんのオンラインスクールでは、オンライン指導経験が豊富な現役のベテランエンジニアが直接監修をしているため、着実にスキルを身に付けながらプログラミングを習得することができます。
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button
        as={Link}
        to='/plans'
        target='_blank'
        rel='noopener noreferrer'
        sx={{ borderRadius: 5 }}
      >
        月額定額プランのご案内
      </Button>
      {/*
        <Button
          variant='white'
          as={Link}
          to='https://pro.diveintohacking.com/p/profile'
        >
          講師紹介
        </Button>
       */}
    </Box>
  </>
)

import React from 'react';
import { Heading, Box } from 'theme-ui';

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3,
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
  },
};

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant="h1" sx={styles.heading}>
      プログラミングは誰にでもできる！
    </Heading>
    <Heading variant="h2" sx={styles.running}>
      プログラミングの習得に特別な素養は必要ありません。
      しかし、習得には効率の良いプロセスを経ることと、１つずつ着実に理解することが必須条件です。
      当スクールでは業界歴20年以上で数万人への指導実績のある経験豊富なベテラン講師が良質な教材を作成し丁寧に解説をしていますので効率的に学ぶことができます。
    </Heading>
    {/*
    <Heading variant='h3'>はむさん</Heading>
       */}
  </Box>
);

import React from 'react'
import { Box, Heading, css } from 'theme-ui'
import { FaUserGraduate } from 'react-icons/fa'

const styles = {
  wrapper: {
    display: `flex`,
    bg: `omegaLighter`,
    position: `relative`,
    zIndex: 2,
    p: 3
  },
  icon: {
    display: `flex`,
    alignItems: `center`,
    mb: 0,
    mx: 3,
    svg: {
      color: `omegaDark`,
      fontSize: 7
    }
  },
  number: {
    textAlign: `center`,
    fontWeight: `bold`,
    color: `omegaDark`,
    mx: 3,
    mb: 0
  },
  text: {
    color: `omegaDark`,
    mb: 0
  }
}
const Stats = () => {
  return (
    <Box sx={styles.wrapper}>
      <Heading variant='h3' sx={styles.icon}>
        <FaUserGraduate />
      </Heading>
      <Heading variant='h3' sx={styles.number}>
        レッスン数
        <Heading variant='h3' sx={styles.text}>
          234
        </Heading>
      </Heading>
      <Heading variant='h3' sx={styles.number}>
        総受講者数
        <Heading variant='h3' sx={styles.text}>
          28596人以上
        </Heading>
      </Heading>
      <Heading variant='h3' sx={styles.number}>
        5☆総獲得数
        <Heading variant='h3' sx={styles.text}>
          257件以上
        </Heading>
      </Heading>
    </Box>
  )
}

export default Stats
